import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfProduct from "@/vue/domain/product/df-product";
import DfStore from "@/vue/domain/store/df-store";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import { Carousel, Slide } from "vue-carousel-variable-width";
const DfCardProductFeauturedComponent = () => Utils.externalComponent2("df-card-product-feautured");

@Component({
  components: {
    DfCardProductFeautured: DfCardProductFeauturedComponent,
    Carousel,
    Slide,
  },
})
export default class DfCardFeauturedComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: null })
  promotion: DfPromotion;
  @Prop({ type: Object, default: null })
  feautured: DfContent;

  get feauturedTitle(): string {
    return Utils.getPropertyValue(this.feautured, Utils.PROPERTY_TITLE, "STRING");
  }

  get feauturedLogoUrl(): string {
    return Utils.getPropertyImageUrl(this.feautured, Utils.PROPERTY_LOGO, null);
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  get productsFeautured(): Array<DfProduct> {
    const productsUniqueId: Array<string> = this.feautured && this.feautured.products ? this.feautured.products : [];
    return this.cachedProducts.filter((product: DfProduct) => productsUniqueId.indexOf(product.uniqueId) > -1);
  }

  @Watch("feautured", { immediate: true })
  private changeVisibility() {
    if (this.feautured) {
      this.loadProductsList();
    }
  }

  private loadProductsList() {
    const productsUniqueId: Array<string> = this.feautured && this.feautured.products ? this.feautured.products : [];

    if (productsUniqueId.length > 0 && productsUniqueId.length !== this.productsFeautured.length) {
      this.$root.$emit("loadCachedProducts", productsUniqueId);
    }
  }
}
